<template>
  <div class="manage_project wrapper">
    <proTitle></proTitle>
    <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
      <div class="formInline-left">
        <el-form-item label="" class="search">
          <el-input @input="filterCourse()" style="width:316px;" v-model="formInline.search"
            suffix-icon="el-icon-search" placeholder="请输入沙龙名称/授课老师" size="mini"></el-input>
        </el-form-item>
      </div>
      <div class="formInline-right">
        <i class="el-icon-circle-plus-outline"></i>
        <span class="csp"
          @click="$router.push(`/salon/create/${$store.state.user.currentProject.id}?breadNumber=4`)">创建沙龙</span>
      </div>
    </el-form>

    <div class="mid-bot">
      <el-table :data="tableData.list">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="id" width="80px" align="center" label="沙龙编号"></el-table-column>
        <el-table-column prop="name" label="沙龙名称" width="160px" align="center"></el-table-column>
        <!-- <el-table-column label="授课模式" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.lessonsPattern == 0 ? "同步课" : "异步课"}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="timeScope" label="开始时间" width="150px" align="center"></el-table-column>
        <el-table-column prop="teacherName" label="授课老师" width="100px" align="center"></el-table-column>
        <el-table-column prop="type" label="操作" fixed="right" width="200px" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="toDetail(scope.row)">查看</el-button>
            <el-button type="text" @click="toEditor(scope.row)" size="small">编辑</el-button>
            <el-button type="text" size="small" @click="isdelete(scope.row.id)">删除</el-button>
            <el-button type="text" size="small" @click="toShare(scope.row)">共享</el-button>
            <br />
            <el-button type="text" size="small" @click="toLessonMange(scope.$index, scope.row)">课节管理</el-button>
            <el-button type="text" size="small" @click="toTeachingData(scope.row)">教学数据</el-button>
            <el-button type="text" size="small" @click="tips">监课评价</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
        :limit.sync="pages.pageSize" :pageSizes="[8,16,24]" @pagination="handlePageChange">
      </pagination>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        salonId: "", //当前选中的课程id
        formInline: { //搜索栏表单数据
          search: "", //模糊查询
          pattern: "" //课程类型
        },
        tableData: [], //项目课列表
        pages: { //分页
          currentPage: 1,
          pageSize: 8,
          total: 0,
        },
      };
    },
    watch: {
      '$store.state.user.currentProject.id': {
        handler(newVal, oldVal) {
          if (!newVal) return;
          this.pages.currentPage = 1;
          this.getSalonList();
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      // console.log(from);
      next(vm => {
        if (from.name == 'courseEdit' || from.name == 'lesson' || (from.matched.length > 0 && from
            .matched[0].name == 'lessonCreate')) {
          vm.getSalonList();
        }
      });
    },
    methods: {
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        // console.log(this.pages);
        this.getSalonList();
      },
      // 监课评价提示
      tips() {
        this.$alert('暂无评价', '监课评价', {
          confirmButtonText: '确定'
        });
      },
      // 删除提示
      isdelete(id) {
        this.salonId = id;
        this.$confirm("确定删除？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.delCourse();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      },
      // 删除课程
      async delCourse() {
        let resData = await this.$Api.Course.salonDel(this.salonId);
        if (resData.code == 200) {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.getSalonList();
        } else {
          this.$message.error(resData.msg);
        }
      },
      //课程列表
      async getSalonList() {
        let params = {
          page: this.pages.currentPage, // 页数
          limit: this.pages.pageSize, //每页个数
          projectId: this.$store.state.user.currentProject.id, //当前项目id
        };
        this.formInline.search ? params.query = this.formInline.search : ""; //筛选--名称、老师、课程标签（模糊查询）
        let resData = await this.$Api.Course.getSalonList(params);
        // console.log(resData);
        if (resData.data) {
          this.tableData = resData.data;
          this.pages.total = this.tableData.totalCount;
        } else {
          this.tableData = [];
        }
      },
      //跳转至课节管理
      toLessonMange(index, row) {
        this.$router.push(`/salon/lessons/${row.id}?breadNumber=4`);
      },
      //跳转至教学数据
      toTeachingData(row) {
        this.$message.info('该功能暂未开通');
        return;
        // console.log(row)
        this.$router.push({
          path: `/teachingdata/${row.id}`,
          query: {
            type: row.lessonsPattern == 1 ? row.lessonsPattern : row.classType,
            breadNumber: 4,
          }
        });
      },
      // 跳转查看详情页面
      toDetail(row) {
        this.$router.push({
          path: `/salon/detail/${row.id}`,
          query: {
            proId: this.$store.state.user.currentProject.id,
            breadNumber: 4,
          }
        });
      },
      // 跳转编辑页面
      toEditor(row) {
        this.$router.push({
          path: `/salon/edit/${row.id}`,
          query: {
            proId: this.$store.state.user.currentProject.id,
            breadNumber: 4,
          }
        });
      },
      //  跳转共享页面
      toShare(row) {
        this.$message.info('该功能暂未开通');
        return;
        this.$router.push({
          path: `/course/share/${row.id}`,
          query: {
            proId: this.$store.state.user.currentProject.id,
            breadNumber: 4,
          }
        });
      },
      //筛选课程 [包括：筛选课程类型，模糊查询课程]
      filterCourse() {
        this.pages.currentPage = 1;
        this.getSalonList();
      },
    },
    mounted() {
      this.getSalonList();
    }
  };
</script>

<style lang="less" scoped>
  @import "../less/table.less";

  .mid-bot {
    margin-top: 20px;
  }
</style>